import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import formatCentsToEurs from '../../Helpers/formatCentsToEurs';

export default function ChallengeOrMissionDetailsModal({
  isOpen,
  onClose,
  title,
  description,
  hashtags,
  mentions,
  requirements,
  reward,
}) {
  return (
    <Modal
      scrollBehavior="inside"
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion border="1px solid #e2e8f0" borderRadius="8px" defaultIndex={[0]} allowMultiple>
            <AccordionItem border="none">
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Descripción del reto</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box
                  fontFamily="DM Sans"
                  fontSize="14px"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem >
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Hashtags</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel fontSize="14px" pb={4}>
                {hashtags.map(h => (
                  <Text key={h} mr={1}>{`#${h}`}</Text>
                ))}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem >
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Menciones</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel fontSize="14px" pb={4}>
                {mentions?.map(m => (
                  <Text key={m} mr={1}>{`@${m}`}</Text>
                ))}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Requisitos</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel fontSize="14px" pb={4}>
                {requirements?.map(r => (
                  <Text key={r} mr={1}>{`- ${r}`}</Text>
                ))}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderBottom="none">
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Recompensas</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel fontSize="14px" pb={4}>
                <p><strong>Fija</strong>: {formatCentsToEurs(reward.fixed)}</p>
                <p><strong>Variable</strong>: {formatCentsToEurs(reward.variable)} </p>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            color="white"
            bg="black"
            _hover={{ bg: 'black', color: 'white' }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
